export default function BlogMain() {

    return (
        <>
            <div className="blog__content__wrapper">

                <section className="section__blog__main">
                    <h1></h1>
                </section>


            </div>
        </>
    )
}