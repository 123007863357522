export default function NotFoundPage(){
    return(
        <>
            <section className="section__fullscreen section__not_found">
                <div className="container__notfound_content">
                    <h1>А, Ой!</h1>
                    <h3>Кажется этой страницы не существует...</h3>
                </div>
            </section>
        </>
    )
}