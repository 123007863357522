import {FormFeedbackWindow} from "../windows/FormFeedbackWindow";
import {useState} from "react";

export default function PricesSection({itemOf, priceList}) {

    const [modalTitle, setModalTitle] = useState('Вызвать мастера');
    const [modalIsOpen, setModalIsOpen] = useState(false);

    function handleClick(e, title){
        e.preventDefault();
        setModalTitle(title);
        setModalIsOpen(true)
    }


    return (

        <>
            <section className={"section__prices"}>
                <div className="prices_list_wrapper">
                    <h1 className={'pl_title'}>Стоимость ремонта <span>частых неисправностей ТВ</span></h1>

                    <ul className="price_list">

                        {priceList.map((item, index) => {

                            return (
                                <li className={'pl_item'} key={index}>
                                    <div className="pl_name">{item.title}</div>
                                    <div className="pl_coast">{item.cost === -1 ? 'Неизвестно' : `${item.prefix ?? ''} ${item.cost} руб.`}</div>
                                    <div className="pl_btn">
                                        <a href="#"
                                           onClick={(e) => handleClick(e, item.title)}
                                           className={'pl_request_call_btn'}
                                        >Заявка на ремонт</a>
                                    </div>
                                </li>
                            )
                        })}

                    </ul>

                </div>

            </section>

            <FormFeedbackWindow
                isVisible={modalIsOpen}
                onClose={() => {
                    setModalIsOpen(false)
                }}
                title={
                    modalTitle
                }
            />

        </>
    )
}