import SectionCatMain from "../components/SectionCatMain";
import {useParams, useSearchParams} from "react-router-dom";
import SectionPrinciples from "../sections/SectionPrinciples";
import SectionWarranty from "../sections/SectionWarranty";
import SectionAdvantages from "../sections/SectionAdvantages";
import SectionAbout from "../sections/SectionAbout";
import SectionReviews from "../sections/SectionReviews";
import SectionContacts from "../sections/SectionContacts";
import PricesSection from "../sections/PricesSection";

export default function TvPage() {

    const params = useParams();
    const [searchParams] = useSearchParams();
    const brand = params['brand'];

    const priceList = [
        {
            title: 'Первичная диагностика',
            cost: 0,
        },
        {
            title: 'Замена материнской платы',
            cost: 1600,
            prefix: 'От'
        },
        {
            title: 'Замена подсветки',
            cost: 1770,
            prefix: 'От'
        },
        {
            title: 'Замена шлейфа',
            cost: 990,
            prefix: 'От'
        },
        {
            title: 'Ремонт разъемов',
            cost: 890,
            prefix: 'От'
        },
        {
            title: 'Ремонт блока питания',
            cost: 890,
            prefix: 'От'
        },
        {
            title: 'Ремонт подсветки',
            cost: 1470,
            prefix: 'От'
        },
        {
            title: 'Ремонт разъемов',
            cost: 650,
            prefix: 'От'
        },
        {
            title: 'Профилактические работы без химической чистки',
            cost: 990,
            prefix: 'От'
        },
        {
            title: 'Замена TV-тюнера',
            cost: 1110,
            prefix: 'От'
        },
        {
            title: 'Ремонт платы управления',
            cost: 990,
            prefix: 'От'
        },
        {
            title: 'Ремонт системной платы',
            cost: 990,
            prefix: 'От'
        },
        {
            title: 'Ремонт инвертора',
            cost: 790,
            prefix: 'От'
        },
        {
            title: 'Замена или ремонт платы T-CON',
            cost: 990,
            prefix: 'От'
        },
        {
            title: 'Перепрошивка телевизора',
            cost: 990,
            prefix: 'От'
        },
        {
            title: 'У меня другая проблема',
            cost: -1
        }
    ]

    const title = searchParams.get('title');
    const titleYellow = searchParams.get('title_yellow');
    const subtitle = searchParams.get('subtitle');


    return (
        <>
            <SectionCatMain
                title_gen={params.title ?? "Телевизоров"}
                brand={brand}
                image={`${process.env.PUBLIC_URL}/sections/pc/master_home.jpg`}
                image_sm={`${process.env.PUBLIC_URL}/sections/pc/pc_cat_main_sm.png`}
                title_main={title}
                subtitle={subtitle}
            />

            <PricesSection itemOf={'Телевизоров'} priceList={priceList}/>

            <SectionPrinciples/>

            <SectionWarranty/>

            <SectionAdvantages tag={"ТЕЛЕВИЗОРЫ"}/>

            <SectionAbout/>

            <SectionReviews/>

            <SectionContacts/>
        </>
    )
}